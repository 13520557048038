import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './pages/layouts/App';
import Header from './pages/layouts/Header';
import Footer from './pages/layouts/Footer';
import ScrollToTop from "./lib/ScrollTop";
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Facilities from './pages/Facilities';
import Placements from './pages/Placements';
import Jobs from './pages/Jobs';
import Testimonials from './pages/Testimonials';
import Courses from './pages/Courses';
import CourseDetails from './pages/CourseDetails';
import Blogs from './pages/Blogs';
import BlogDetails from './pages/BlogDetails';
import ArticleDetails from './pages/ArticleDetails';
import InstitutionDetails from './pages/InstitutionDetails';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import Thanks from './pages/Thanks';
import ApplicationForm from './pages/ApplicationForm';
import ApplicationThanks from './pages/ApplicationThanks';
import PageNotFound from './pages/PageNotFound';
import Loader from './lib/Loader';
import loadjs from 'loadjs';

function SenfineMain() {
    
  loadjs('/assets/js/bootsnav.js', () => {});
//   loadjs('/assets/js/senfine1.js', () => {});
  console.log(atob("QGltYmF6aW0gLSBodHRwczovL3guY29tL2ltYmF6aW0gLyBodHRwczovL2luc3RhZ3JhbS5jb20vaW1iYXppbQ=="));
  
  return (
      <>
      <ScrollToTop/>
      <Header/>
      <Routes>
        <Route path="/" element={<Home /> } />
        <Route path="/about-us" element={<About /> } />
        <Route path="/contact-us" element={<Contact /> } />
        <Route path="/facilities" element={<Facilities /> } />
        <Route path="/placements" element={<Placements /> } />
        <Route path="/jobs" element={<Jobs /> } />
        <Route path="/testimonials" element={<Testimonials /> } />
        <Route path="/courses" element={<Courses /> } />
        <Route path="/courses/:id" element={<CourseDetails /> } />
        <Route path="/blogs" element={<Blogs /> } />
        <Route path="/blogs/:id" element={<BlogDetails /> } />
        <Route path="/articles/:id" element={<ArticleDetails /> } />
        <Route path="/institutions/:id" element={<InstitutionDetails /> } />
        <Route path="/privacy-policy" element={<PrivacyPolicy /> } />
        <Route path="/terms-and-conditions" element={<TermsConditions /> } />
        <Route path="/application-form" element={<ApplicationForm /> } />
        <Route path="/application-thanks" element={<ApplicationThanks /> } />
        <Route path="/thanks" element={<Thanks /> } />
        <Route path="*" element={<PageNotFound />}/>
      </Routes>
      <Footer/>
      <App/>
      </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('senfineAcademy'));
root.render(
    <BrowserRouter>
      <SenfineMain />
    </BrowserRouter>
);