import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function About() {
  return (
    <div className="about">
      {/* <Helmet>
        <title>About us | Senfine Academy</title>
        <meta name="description" content="Senfine Academy is an Edu-Tech company that provides certification courses in many segments. Our main aim is to provide high-quality education and help students to boost their careers."/>
        <meta name="keywords" content="data mining with python, data analytics, search engine optimization, social media optimization, ai start-ups and innovations, certification in sales management, professional certification in graphic design, ielts / oet, communicative english, certification in educational psychology, certification in child psychology, certification in clinical psychology, big data, cybersecurity & internet of things (iot), digital marketing & communication, cloud computing"/>
        <link rel="canonical" href="https://www.senfineacademy.com/about-us" />
      </Helmet> */}
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>About Senfine Academy</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">About us</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="assets/img/about-us-img.png" loading="lazy" draggable="false" alt="About us" />
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container">
            <div className="row">
                <div className="about-info">
                    <div className="col-md-12 info">
                        <h2>Who are We</h2>
                        <p><b>Senfine Academy Pvt. Ltd.</b> is your gateway to excellence in academic certification in Bangalore. We take pride in offering the <b>best academic certification courses in Bangalore</b>, tailored for both working professionals and ambitious students. Our cutting-edge programmes empower you to upskill and transact seamlessly into new domains, ensuring a rewarding career progression. Each Senfine certification programme is meticulously curated by industry experts, incorporating in-depth research, surveys, and peer insights. Join us on this transformative journey through Senfine short time programs and unlock the <b>best certification training courses in Bangalore</b>. Invest in your future with Senfine Academy courses, where your success is our ultimate goal.</p>
                        <p>The <b>Senfine certifications</b> are offered in a unique blended model of both classroom learning and online modes using the latest and cutting-edge technologies across different technology domains. The hybrid classes provide the students with a new age portal-based learning, while the offline classes are conducted at the fully-furnished and tech-savvy classrooms located in MG Road Bangalore, the heart of the city. The Senfine short time courses are carefully curated to upskill both professionals and students, looking for both job opportunities and career progression across an array of domains.</p>
                        <p>Senfine courses combine a unique approach to the ideation and creation of course content that is relevant to the current industry requirements. The courses are designed such that anyone with an interest to learn and upskill themselves can enrol and seek career progression. Working professionals irrespective of their job domains and looking for a change in their careers can join the certification courses from Senfine Academy to switch careers with no extra effort.</p>
                        <p>Senfine Bangalore strategically collaborates with Subject Matter Experts (SMEs) across different domains for providing effective training modules for the students of Senfine Academy. Our students will be given exclusive technological support from our peers.</p>
                        <p>The Senfine online programs are carefully articulated to provide professional help and ensure that the students can find lucrative jobs for career progression or domain change. The <b>Senfine placements</b> Cell has highly experienced team members, they will assist students with many job-centric skills like mock interview sessions, personality development skills, interpersonal skills and many others to elevate and upskill our students and help them to find the best jobs in the world through Senfine institute courses.</p>
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>
  );
}

export default About;
