import React from "react";
import { useState, useEffect } from 'react';
import CountryJs  from '../../lib/Country';
import { Link, createBrowserRouter, RouterProvider } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';

function Footer() {

   const [countries, setCountry] = useState('')
   const [states, setStates] = useState([])
   const [stateValue, setStateValue] = useState([]);
   const url = window.location.pathname.split('/').pop();

   function ContactSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    axios.post('/store-contact-enuiry', formData)
    .then(({data})=>{
       event.target.reset();
       if(data.status === 200){
        Swal.fire({
            text:'Thank you!. We will get back to you shortly..',
            icon:"success"
        })
       }
       else if(data.status === 201){
           Swal.fire({
               text:'Please fill required fields',
               icon:"error"
           })
       }
       else{
           Swal.fire({
               text:'Error please try again later!',
               icon:"error"
           })
       }
    })
  }
   
       function selectCountry (event) {
           setStateValue([])
           const state_arr = CountryJs.s_a[event.key].split("|"); 
           const stateArray = [];
           stateArray.push({
             value: '', 
             label: 'Select'
           });
           for (var i = 0; i < state_arr.length; i++) {
             stateArray.push({
             value: state_arr[i], 
             label: state_arr[i]
             });
           }
           setStates(stateArray)
       }
           
       function selectRegion (val) {
           if(val.value !== '' && val.value !== null){
             setStateValue(val)
           } else {
             setStateValue([])
           }
      }

      useEffect(()=>{
         const countryArray = [];
         var country1 = CountryJs.country_arr;
         countryArray.push({
           value: '', 
           label: 'Select',
           key:[0]
         });
         for (var i = 0; i < country1.length; i++) {
           countryArray.push({
             value: country1[i],
             label: country1[i],
             key:[i+1]
           });
         }
         setCountry(countryArray);
     },[url]);

   return (
      <footer className="bg-darker text-white">
        <div className="footer-links text-light">
            <div className="container">
            <div className="row">
                    <div className="col-md-6 links-col">
                        <h5>Best Certification Courses in Bangalore</h5>
                        <p><Link to="https://www.senfineacademy.com/courses/cloud-computing-certification-course" title="Short-Term Cloud Computing Course in Bangalore">Short-Term Cloud Computing Course in Bangalore</Link></p>
                        <p><Link to="https://www.senfineacademy.com/courses/artificial-intelligence-machine-learning" title="Best AI ML Course in Bangalore">Best AI ML Course in Bangalore</Link></p>
                        <p><Link to="https://www.senfineacademy.com/courses/cyber-security-course-in-bangalore" title="Short-Term Cyber Security Course in Bangalore">Short-Term Cyber Security Course in Bangalore</Link></p>
                        <p><Link to="https://www.senfineacademy.com/courses/data-science" title="Short-Term Data Science Course in Bangalore">Short-Term Data Science Course in Bangalore</Link></p>
                        <p><Link to="https://www.senfineacademy.com/courses/digital-marketing-certification-training-course-in-bangalore" title="Best Digital Marketing Course in Bangalore">Best Digital Marketing Course in Bangalore</Link></p>
                    </div>
                    <div className="col-md-6 links-col">
                        <h5>Top English Language Certification Courses in Bangalore</h5>
                        <p><Link to="https://www.senfineacademy.com/courses/advanced-english-course-in-bangalore" title="Spoken English Language Course in Bangalore">Spoken English Language Course in Bangalore</Link></p>
                        <p><Link to="https://www.senfineacademy.com/courses/advanced-english-course-in-bangalore" title="Corporate English Course in Bangalore">Corporate English Course in Bangalore</Link></p>
                        <p><Link to="https://www.senfineacademy.com/courses/advanced-english-course-in-bangalore" title="Spoken English Course for Beginners">Spoken English Course for Beginners</Link></p>
                        <p><Link to="https://www.senfineacademy.com/courses/ielts-course-in-bangalore" title="IELTS Preparation Course in Bangalore">IELTS Preparation Course in Bangalore</Link></p>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-main">
        <div className="container">
            <div className="row">
                <div className="f-items">
                    <div className="col-md-4 col-sm-6 item">
                        <div className="f-item link">
                            <h4>Company</h4>
                            <ul>
                                <li>
                                    <Link to="/about-us">About us</Link>
                                </li>
                                <li>
                                    <Link to="/placements">Placements</Link>
                                </li>
                                <li>
                                    <Link to="/features">Features</Link>
                                </li>
                                <li>
                                    <Link to="/blogs">Blogs</Link>
                                </li>
                                <li>
                                    <Link to="/testimonials">Testimonials</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Contact us</Link>
                                </li>
                                <li>
                                    <Link to="/application-form">Application Form</Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/terms-and-conditions">Payment Terms & Conditions</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="f-item app">
                            <h5 style={{ margin: "20px 0 15px 0"}}>Install Senfine Students Portal App</h5>
						    <a href="https://play.google.com/store/apps/details?id=com.imbazim.SenfineStudentsPortal" title="Get Senfine Students Portal App on Google Play" target="_blank"><img className="senfine-gplay" style={{ marginBottom: "0" }} src="/assets/img/GetItOnGooglePlay_Badge_Web_color_English.png" alt="Senfine Students Portal App" /></a>
                        </div>
                    </div>
                    <div className="col-md-5 item">
                        <div className="f-item">
                            <hr style={{borderTop: "1px solid rgba(255, 255, 255, 0.2)", marginBottom: "20px"}} className="hidden visible-xs" />
                            <h4>Follow us</h4>
                            <ul className="social-new">
							   <li><a href="https://www.facebook.com/profile.php?id=61557708282766" target="_blank" title="Senfine Academy Facebook"><i className="fab fa-facebook"></i></a></li>
							   <li><a href="https://twitter.com/SenfineAcademy" target="_blank" title="Senfine Academy Twitter"><i className="fab fa-twitter"></i></a></li>
							   <li><a href="https://www.instagram.com/senfineacademy/" target="_blank" title="Senfine Academy Instagram"><i className="fab fa-instagram"></i></a></li>
							   <li><a href="https://www.linkedin.com/company/senfine-academy/" target="_blank" title="Senfine Academy LinkedIn"><i className="fab fa-linkedin"></i></a></li>
							   <li><a href="https://www.youtube.com/channel/UCFiKZkVnDKeiXsA-Sddabxg" target="_blank" title="Senfine Academy Youtube"><i className="fab fa-youtube"></i></a></li>
							   <li><a href="https://www.pinterest.com/SenfineAcademy/" target="_blank" title="Senfine Academy Pinterest"><i className="fab fa-pinterest"></i></a></li>
						    </ul>
                        </div>
                        <div className="f-item app">
                            <h5 style={{ margin: "20px 0 15px 0"}}>Scan to follow our Instagram</h5>
						    <img className="senfine-insta" style={{ marginBottom: "0" }} src="/assets/img/senfine-insta.webp" alt="Senfine Academy Instagram" />
                        </div>
                        <div className="f-item address">
                            <ul>
                                <li>
                                    <i className="fas fa-envelope"></i> 
                                    <p>Email 
                                    <span>
                                        <a href="mailto:info@senfineacademy.com">info@senfineacademy.com</a>
                                    </span>
                                    </p>
                                </li>
                                <li>
                                    <i className="fas fa-phone"></i> 
                                    <p>Phone 
                                    <span>
                                        <a href="tel:+919845412266">+91 9845412266</a> / <a href="tel:+918022223530">080-22223530</a>
                                    </span>
                                    </p>
                                </li>
                            </ul>
                            
                        </div>
                        
                    </div>
                    <div className="col-md-3 item">
                        <div className="f-item reg-area footer-form">
                            <h4>Submit your Query</h4>
                            <div className="reg-form">
                                <form onSubmit={ContactSubmit} className="form contact-form">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input className="form-control" name="name" placeholder="Name *" type="text" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input className="form-control" name="email" placeholder="Email *" type="email" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input className="form-control" name="phone" id="phone" placeholder="Phone *" type="text" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea className="form-control" name="message" placeholder="Message" required></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <button className="btn btn-primary effect" type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="col-md-6">
                            <p>Copyright &copy; Senfine Academy - {new Date().getFullYear()}. <br className="hidden-lg" />All Rights Reserved</p>
                        </div>
                        <div className="col-md-6 text-right link hidden-xs text-light">
                                <ul>
                                    <li>
                                        <a href="#senfineScholarships" data-toggle="modal" data-target="#senfineScholarships">Get Scholarships</a>
                                    </li>
                                    <li>
                                        <a href="#senfineBrochure" data-toggle="modal">Get Brochure</a>
                                    </li>
                                    <li>
                                        <Link to="/application-form">Apply Online</Link>
                                    </li>
                                </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
   );
}

export default Footer;
