import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import jQuery from 'jquery';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ReactSearchAutocomplete } from "react-search-autocomplete";

function Header() {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/"); 
    const [institutions, setAllInstitutions] = useState([]);
    const [coursesList, setCourses] = useState([]);  
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        fetchInstitutions();
    },[])
    
    const fetchInstitutions = async () => {
        setIsLoading(true)
        await axios.get(`/all-institutions`).then(({data})=>{
          setAllInstitutions(data.institutions)
          setIsLoading(false)
        }).catch(({response:{data}})=>{
          setIsLoading(false)
          Swal.fire({
            text:'Error!.',
            icon:"error"
          })
        })
    }
    useEffect(()=>{
        const fetchCourses = async () => {
            await axios.get(`/search-courses`).then(({data})=>{
                setCourses(data.allcourses)
            //   console.log(data);
            })
          }
          fetchCourses();
    },[setCourses]);

    function closeMenu() {
        var opened = jQuery('.navbar-collapse').hasClass('collapse in');
        if ( opened === true ) {
            jQuery('.navbar-collapse').removeClass('in');
            jQuery('.navbar-toggle').addClass('collapsed');
        }
    }
    jQuery('body').on('click', function(e) {
    if(jQuery(e.target).closest('.navbar').length == 0) {
        var opened = jQuery('.navbar-collapse').hasClass('collapse in');
        if ( opened === true ) {
            // jQuery('.navbar-collapse').collapse('hide');
            jQuery('.navbar-collapse').removeClass('in');
            jQuery('.navbar-toggle').addClass('collapsed');
        }
    }
    });

    var searchInput = jQuery('.dYrfwz > input');
    var searchBtn = jQuery('.attr-nav li.search');
    var searchIcon = jQuery('.attr-nav li.search .search-icon i');
    var searchForm = jQuery('.courses-search');
    
    const handleOnSelect = (course) => {
        navigate('/courses/'+course.slug, {replace: true});
        searchForm.slideUp();
        searchIcon.toggleClass('fa-times fa-search');
        searchBtn.removeClass('open');
        searchInput.val('');
    }

    function onCoursesSearch() {
        searchBtn.toggleClass('open');
        searchIcon.toggleClass('fa-search fa-times');
        if (searchBtn.hasClass('open')) {
           searchForm.slideDown();
           searchInput.focus();
        } else {
           searchForm.slideUp();
           searchInput.blur();
        }
    }

   return (
      <div className="header">
         <div className="top-bar-area address-one-lines bg-gradient text-light hidden-xs">
         <div className="container">
            <div className="row">
                <div className="col-md-6 address-info">
                    <div className="info box">
                        <ul>
                            <li>
                                <i className="fas fa-phone"></i>
                                <a href="tel:+919845412266"> +91 9845412266</a>
                            </li>
                            <li>
                                <i className="fas fa-phone-square"></i>
                                <a href="tel:+918022223530"> 080-22223530</a>
                            </li>
                            <li>
                                <i className="fas fa-envelope"></i>
                                <a href="mailto:admin@senfineacademy.com">admin@senfineacademy.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="text-right col-md-6 info-links hidden-xs">
                    <div className="info box">
                        <ul>
                            <li>
                                <Link to="#senfineScholarships" data-toggle="modal" data-target="#senfineScholarships">Get Scholarships</Link>
                            </li>
                            {/* <li>
                               <Link to="#senfineCareers" data-toggle="modal" data-target="#senfineCareers">Careers</Link>-->
                            </li> */}
                            <li>
                                <Link to="#senfineBrochure" data-toggle="modal" data-target="#senfineBrochure">Get Brochure</Link>
                            </li>
                            <li>
                                <Link className="btn btn-success" to="#senfineApply" data-toggle="modal" data-target="#senfineApply"><i className="fa fa-check" aria-hidden="true"></i> Apply Now</Link>
                            </li>
                            <li>
                                <div className="dropdown login-btn">
                                  <button className="btn btn-danger dropdown-toggle" type="button" id="loginBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-sign-in-alt"></i> Login</button>
                                  <ul className="dropdown-menu" aria-labelledby="loginBtn">
                                    <li><Link target="_blank" to="https://portal.senfineacademy.in/student/login"><i className="fas fa-user-graduate"></i>  Students</Link></li>
                                    <li><Link target="_blank" to="https://portal.senfineacademy.in/teacher/login"><i className="fas fa-user-tie"></i> Teachers</Link></li>
                                  </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <header id="home">
        <nav className="navbar navbar-default navbar-sticky bootsnav">
            <div className="container">
                <div className="row">
                    <div className="courses-search">
                        <ReactSearchAutocomplete
                          items={coursesList}
                          onSelect={handleOnSelect}
                          autoFocus={true}
                          placeholder="Search Courses"
                          showItemsOnFocus={true}
                        />  
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="attr-nav">
                    <ul>
                        <li className="search" onClick={onCoursesSearch}><span className="search-icon"><i className="fa fa-fw fa-search"></i></span></li>
                    </ul>
                </div>        

                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-menu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <Link className="navbar-brand" to="/">
                        <img src="/assets/img/logo.svg" loading="lazy" className="logo" draggable="false" alt="Senfine Academy" />
                        <p>Recognised by Govt. of Karnataka</p>
                    </Link>
                </div>

                <div className="collapse navbar-collapse" id="navbar-menu" role="navigation" itemScope itemType="https://schema.org/SiteNavigationElement">
                    <ul className="nav navbar-nav navbar-right" data-in="#" data-out="#" role="menu">
                        <meta itemProp="name" content="Main Menu" />
                        <li className={`${splitLocation[1] === "" ? "active" : ""}`} onClick={closeMenu}>
                            <Link to="/" itemProp="url">Home</Link>
                        </li>
                        <li className={`${splitLocation[1] === "about-us" ? "active" : ""}`} onClick={closeMenu}>
                            <Link to="/about-us" itemProp="url">About us</Link>
                        </li>
                        <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Our Departments</a>
                            <ul className="dropdown-menu">
                            {institutions && institutions.length > 0 && institutions.map((institution, index) => {
							    return (
                                <li onClick={closeMenu} key={index}><Link to={`/institutions/${institution.slug}`} itemProp="url">{institution.name}</Link></li>
                                );
                            })}
                            </ul>
                        </li>
                        <li className={`${splitLocation[1] === "courses" ? "active" : ""}`} onClick={closeMenu}>
                            <Link to="/courses" itemProp="url"><span className="btn-menu courses">Courses</span></Link>
                        </li>
                        <li className={`${splitLocation[1] === "placements" ? "active" : ""}`} onClick={closeMenu}>
                            <Link to="/placements" itemProp="url">Placements</Link>
                        </li>
                        <li className={`${splitLocation[1] === "facilities" ? "active" : ""}`} onClick={closeMenu}>
                            <Link to="/facilities" itemProp="url">Facilities</Link>
                        </li>
                        <li className={`${splitLocation[1] === "blogs" ? "active" : ""}`} onClick={closeMenu}>
                            <Link to="/blogs" itemProp="url"><span className="btn-menu blogs">Blogs</span></Link>
                        </li>
                        <li className={`${splitLocation[1] === "jobs" ? "active" : ""}`} onClick={closeMenu}>
                            <Link to="/jobs" itemProp="url">Jobs</Link>
                        </li>
                        <li className={`${splitLocation[1] === "contact-us" ? "active" : ""}`} onClick={closeMenu}>
                            <Link to="/contact-us" itemProp="url">Contact us</Link>
                        </li>
                    </ul>

                    <div className="mob-info hidden visible-xs">
                        <ul>
                            <li onClick={closeMenu}>
                                <Link to="#senfineScholarships" data-toggle="modal" data-target="#senfineScholarships">Get Scholarships</Link>
                            </li>
                            {/* <li>
                                <Link to="#senfineCareers" data-toggle="modal" data-target="#senfineCareers">Careers</Link>
                            </li> */}
                            <li onClick={closeMenu}>
                                <Link to="#senfineBrochure" data-toggle="modal" data-target="#senfineBrochure">Get Brochure</Link>
                            </li>
                            <li onClick={closeMenu}>
                                <Link className="btn btn-success" to="#senfineApply" data-toggle="modal" data-target="#senfineApply"><i className="fa fa-check" aria-hidden="true"></i> Apply Now</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="login-area hidden visible-xs">
                          <ul>
                            <li>
                                <p><i className="fas fa-sign-in-alt"></i> Login</p>
                            </li>
                            <li>
                                <Link className="btn btn-default" target="_blank" to="https://portal.senfineacademy.in/student/login"><i className="fas fa-user-graduate"></i> Students</Link>
                            </li>
                            <li>
                                <Link className="btn btn-default" target="_blank" to="https://portal.senfineacademy.in/teacher/login"><i className="fas fa-user-tie"></i> Teachers</Link>
                            </li>
                          </ul>
                        </div>
                </div>
            </div>
        </nav>
    </header>
   </div>
   );
}

export default Header;
