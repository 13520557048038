import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../pages/InstitutionDetails.css";
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';
import { useEffect, useState } from 'react';
import PageNotFound from '../pages/PageNotFound';

function InstitutionDetails() {
    const params = useParams();
    const [institution, setThisInstitution] = useState([])
    const [allinstitutions, setAllInstitutions] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

    useEffect(()=>{
        fetchProduct(params.id);
    },[params.id])
    
    const fetchProduct = async () => {
        setIsLoading(true)
        await axios.get(`/institution-page-data/${params.id}`).then(({data})=>{
          setThisInstitution(data.institution)
          setAllInstitutions(data.allinstitutions)
          setIsLoading(false)
        }).catch(({response:{data}})=>{
            setIsLoading(false)
            return navigate("404");
        })
      }
      function InstitutionDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ ' ' +month+', ' +year;
    }
    function ThisInstitutionDate(par){
        const months = ["01","02","03","04","05","06","07","08","09","10","11","12"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        const hour = today.getHours();
        const minute = today.getMinutes();
        const second = today.getSeconds();
        return year+'-'+month+'-'+date+'T'+hour+':'+minute+':'+second+'+05:30';
    }
    var imgUrl = 'https://www.senfineacademy.com/storage/institutions/'+institution.image2;
  return (
    institution!==null?
    <div className="institution-details">
      {/*<Helmet>
        <title>{institution.meta_title}</title>
        <meta name="description" content={institution.meta_des}/>
        <meta name="keywords" content={institution.meta_key}/>
        <link rel="canonical" href={`https://www.senfineacademy.com/institutions/${institution.slug}`} />
        <meta property="article:publisher" content="https://www.facebook.com/senfineacademy" />
        <meta property="article:author" content="https://www.facebook.com/senfineacademy" />
        <meta property="article:section" content={institution.name}/>
        <meta property="article:published_time" content={ThisInstitutionDate(new Date(institution.created_at))}/>
        <meta property="article:modified_time" content={ThisInstitutionDate(new Date(institution.created_at))} />
        <meta property="og:title" content={institution.name} />
        <meta property="og:url" content={`https://www.senfineacademy.com/institutions/${institution.slug}`} />
        <meta property="og:description" content={institution.meta_des} />
        <meta property="og:updated_time" content={ThisInstitutionDate(new Date(institution.created_at))} />
        <meta property="og:image" content={`https://www.senfineacademy.com/storage/institutions/${institution.image2}`} />
        <meta property="og:image:secure_url" content={`https://www.senfineacademy.com/storage/institutions/${institution.image2}`} />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="402" />
        <meta property="og:image:alt" content={institution.name} />
      </Helmet>*/}
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'linear-gradient(130deg, rgb(0 33 71 / 80%),rgb(0 33 71 / 20%)), url(' + imgUrl + ')'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>{institution.name}</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">{institution.name}</li>
                    </ul>
                    <div className="course-details-area">
                    <div className="course-details-info">
                        <p className="updated-p">Last updated: {InstitutionDate(new Date(institution.updated_at))}</p>
                    <div className="course-meta">
                        <div className="item rating">
                            <h4>Rating</h4>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star-half-alt"></i>
                            <span> 4.5 (16)</span>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className="course-details-area default-padding">
        <div className="container">
            {isLoading ? (
             <div className="row">
             <div className="col-md-12 text-center">
                 <RotatingLines
                    visible={true}
                    height="40"
                    width="40"
                    strokeColor="#0476bc"
                    strokeWidth="4"
                    animationDuration="0.75"
                    ariaLabel="Loading"
                 />
                 <p>Please wait...</p>
             </div>
             </div>
            ) : (
            <div className="row">
                <div className="col-md-8">
                    <div className="course-details-info">
                        <div className="info title">
                            <div dangerouslySetInnerHTML={{ __html: institution.content }} />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="sidebar">
                    <aside>
                            <div className="sidebar-item recent-post">
                                <div className="title">
                                    <h4>Other Institutions</h4>
                                </div>
                                {allinstitutions && allinstitutions.length > 0 && allinstitutions.map((allinstitution, index) => {
                                    return (
                                <div className="item" key={index}>
                                    <div className="content">
                                        <div className="thumb">
                                            <Link to={`/institutions/${allinstitution.slug}`} style={{ padding: "14px 0" }}>
                                                <img src={`/storage/institutions/${allinstitution.image}`} loading="lazy" draggable="false" alt={allinstitution.name} />
                                            </Link>
                                        </div>
                                        <div className="info">
                                            <h5>
                                                <Link to={`/institutions/${allinstitution.slug}`}>{allinstitution.name}</Link>
                                            </h5>
                                            <div className="course-meta">
                                                <div className="item rating">
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star-half-alt"></i>
                                                    <span> 4.5 (16)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    );
                                })}
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        )}
    </div>
    </div>
    </div>
    :
    <h1> Can't connect to server ! </h1>
  );
}

export default InstitutionDetails;
