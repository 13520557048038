import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Thanks() {
  return (
    <div className="thanks">
      {/* <Helmet>
        <title>Thanks | Senfine Academy</title>
        <meta name="description" content="Senfine academy is the perfect place to learn new skills and advance your career. With our online courses, you can get certified in your chosen field quickly and easily. Apply now!"/>
        <meta name="keywords" content="Apply online, Online form, Online application form"/>
        <link rel="canonical" href="https://www.senfineacademy.com/thanks"/>
      </Helmet> */}
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Thank you</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Thank you</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="assets/img/form-img.png" loading="lazy" alt="Thanks" />
        </div>
      </div>
      <div className="login-area default-padding">
        <div className="container">
            <div className="row reg-area">
                <div className="col-md-6 col-md-offset-3 reg-form">
                    <div className="form-box text-center">    
                        <i className="fa fa-3x fa-check-circle text-success" aria-hidden="true" style={{marginBottom:'20px'}}></i>
                        <h3>Thank you</h3>
                        <p>Thank you for submitting the Enquiry. Our Admission Officer will Callback you shortly.</p>
                        <Link className="btn btn-info" to="/" style={{margin: '5px'}}><i className="fa fa-angle-left" aria-hidden="true"></i> Home</Link> 
                        <a download className="btn btn-success" href="/assets/pdf/Senfine_Brochure_2023_New_Compressed.pdf" style={{margin: '5px'}}><i className="fa fa-file-pdf" aria-hidden="true"></i> Download Brochure</a> 
                    </div>
                </div>
            </div>
        </div>
      </div>
  
    </div>
  );
}

export default Thanks;
