import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import "../pages/Facilities.css";

function Facilities() {
  return (
    <div className="facilities">
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Facilities of Senfine Academy</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Facilities</li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container">
            <div className="row">
                <div className="features-info">
                    <div className="col-md-12 info">
                        <h2>Facilities</h2>
                        <div className="facilities-boxes">
                            <div className="facilities-boxes-grid">
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-1.webp" loading="lazy" draggable="false" alt="Qualified and Dedicated Faculty Members with a Passion for Teaching" />
                                    </div>
                                    <div className="info">
                                        <p>Qualified and Dedicated Faculty Members</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-2.webp" loading="lazy" draggable="false" alt="Excellent Infrastructure" />
                                    </div>
                                    <div className="info">
                                        <p>Excellent Infrastructure</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-3.webp" loading="lazy" draggable="false" alt="Experiential and Group-Based Pedagogy" />
                                    </div>
                                    <div className="info">
                                        <p>Experiential and Group-Based Pedagogy</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-4.webp" loading="lazy" draggable="false" alt="Industry Oriented Curriculum" />
                                    </div>
                                    <div className="info">
                                        <p>Industry Oriented Curriculum</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-5.webp" loading="lazy" draggable="false" alt="Outcome Based Learning" />
                                    </div>
                                    <div className="info">
                                        <p>Outcome Based Learning</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-6.webp" loading="lazy" draggable="false" alt="Development of Research Skills" />
                                    </div>
                                    <div className="info">
                                        <p>Development of Research Skills</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-7.webp" loading="lazy" draggable="false" alt="International Collaborations" />
                                    </div>
                                    <div className="info">
                                        <p>International Collaborations</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-8.webp" loading="lazy" draggable="false" alt="Placements Assistance" />
                                    </div>
                                    <div className="info">
                                        <p>Placements Assistance</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-9.webp" loading="lazy" draggable="false" alt="Value Based Education" />
                                    </div>
                                    <div className="info">
                                        <p>Value Based Education</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-10.webp" loading="lazy" draggable="false" alt="Clean and Healthy Environment" />
                                    </div>
                                    <div className="info">
                                        <p>Clean and Healthy Environment</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-11.webp" loading="lazy" draggable="false" alt="Healthy and Interactive Student - Teacher ratio" />
                                    </div>
                                    <div className="info">
                                        <p>Healthy and Interactive Student - Teacher ratio</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-12.webp" loading="lazy" draggable="false" alt="Student Centered Approach" />
                                    </div>
                                    <div className="info">
                                        <p>Student Centered Approach</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-13.webp" loading="lazy" draggable="false" alt="Cultural Activities Functions and celebrations" />
                                    </div>
                                    <div className="info">
                                        <p>Cultural Activities Functions and celebrations</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-14.webp" loading="lazy" draggable="false" alt="Focus on Inculcating of Human Values" />
                                    </div>
                                    <div className="info">
                                        <p>Focus on Inculcating of Human Values</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-15.webp" loading="lazy" draggable="false" alt="Guest Lectures by Eminent Speakers" />
                                    </div>
                                    <div className="info">
                                        <p>Guest Lectures by Eminent Speakers</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-16.webp" loading="lazy" draggable="false" alt="Competitions to tap the inherent talent and build self-confidence" />
                                    </div>
                                    <div className="info">
                                        <p>Competitions to tap the inherent talent and build self-confidence</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-17.webp" loading="lazy" draggable="false" alt="Innovative Projects to kindle the student's creativity" />
                                    </div>
                                    <div className="info">
                                        <p>Innovative Projects to kindle the student's creativity</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-18.webp" loading="lazy" draggable="false" alt="Spacious and Well-Ventilated Classrooms" />
                                    </div>
                                    <div className="info">
                                        <p>Spacious and Well-Ventilated Classrooms</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-19.webp" loading="lazy" draggable="false" alt="Well Equipped Labs with iMac" />
                                    </div>
                                    <div className="info">
                                        <p>Well Equipped Labs with iMac</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-20.webp" loading="lazy" draggable="false" alt="Professional Clubs and Youth forums" />
                                    </div>
                                    <div className="info">
                                        <p>Professional Clubs and Youth forums</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-21.webp" loading="lazy" draggable="false" alt="Career Planning & Guidance Cell" />
                                    </div>
                                    <div className="info">
                                        <p>Career Planning & Guidance Cell</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-22.webp" loading="lazy" draggable="false" alt="Secured Several Funded Research Projects" />
                                    </div>
                                    <div className="info">
                                        <p>Secured Several Funded Research Projects</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-23.webp" loading="lazy" draggable="false" alt="Publication in Renowned International / National Journals" />
                                    </div>
                                    <div className="info">
                                        <p>Publication in Renowned International / National Journals</p>
                                    </div>
                                </div>
                                <div className="facility-box">
                                    <div className="thumb">
                                        <img src="/assets/img//facilities/facilities-24.webp" loading="lazy" draggable="false" alt="Rich Alumni Base" />
                                    </div>
                                    <div className="info">
                                        <p>Rich Alumni Base</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>
  );
}

export default Facilities;
