import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../pages/Placements.css";

function Placements() {
  return (
    <div className="placements">
      {/* <Helmet>
        <title>Placements | Senfine Academy</title>
        <meta name="description" content="Senfine academy offers a comprehensive set of features to help you learn faster and smarter. Our courses are designed to be engaging, interactive, and tailored to your individual learning style."/>
        <meta name="keywords" content="data mining with python, data analytics, search engine optimization, social media optimization, ai start-ups and innovations, certification in sales management, professional certification in graphic design, ielts / oet, communicative english, certification in educational psychology, certification in child psychology, certification in clinical psychology, big data, cybersecurity & internet of things (iot), digital marketing & communication, cloud computing"/>
        <link rel="canonical" href="https://www.senfineacademy.com/placements" />
      </Helmet> */}
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Top Placement Institute in Bangalore</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Placements</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="assets/img/features-img.png" loading="lazy" alt="Senfine Placement Cell" />
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container">
            <div className="row">
                <div className="placements-info">
                    <div className="col-md-12 info">
                        <h2>Senfine Placement Cell</h2>
                        <p>The placement cell at Senfine Academy is truly committed to the well–being of its students. Being one of the <b>Top Placement Institute in 
                        Bangalore</b> the cell helps in working out each student’s career objective as efficiently as possible, by providing them with all essential guidance
                        and opportunities. Career goals, professional support and industry connections are focused on by the team, while taking personal attention into 
                        consideration to ensure students have bright career prospects. Those students who complete the course and its respective assessments at Senfine Academy
                        are awarded certificates in recognition of effort and achievement. The certificates indicate the extent to which they have acquired skills and knowledge
                        from their study. Students with a <b>UG/PG</b> degree will be eligible to attend placement interviews.</p>
                        <p>The <b>placement cell of Senfine Academy</b> puts in all kinds of effort to arrange various interviews with different organizations for students. 
                        In addition to providing opportunities for an interview, the cell also helps students with a series of programs to improve performance. The placement
                        cell guarantees with multiple interviews hence proving you the required placement assistance. Senfine Academy has established very strong linkages with
                        many national and multinational companies. This greatly opens up opportunities for our learners. Such collaborations thus make it possible for students
                        to involve themselves in a field of abundant career opportunities, internships, and real exposure towards industry-specific knowledge.</p>
                        <p>The selection will be based on the <b>performance</b> and <b>skills</b> of the students. The Placement Cell is the guiding force in the professional success of students
                        and will hence play an important role in the journey of students toward career success. It empowers the student to be confident in the job market with needed resources,
                        counseling, and other forms of support.</p>
                        <div className="steps">
                            <div className="icon-boxes">
                                <div className="icon-box line">
                                    <div className="icon"><span>1</span></div>
                                    <h4 className="title">Joining of the Course</h4>
                                </div>
                                <div className="icon-box line">
                                    <div className="icon"><span>2</span></div>
                                    <h4 className="title">Training and Skill Development Activities</h4>
                                </div>
                                <div className="icon-box line">
                                    <div className="icon"><span>3</span></div>
                                    <h4 className="title">Senfine Assessment Test</h4>
                                </div>
                                <div className="icon-box line">
                                    <div className="icon"><span>4</span></div>
                                    <h4 className="title">Get Senfine Certification</h4>
                                </div>
                                <div className="icon-box line">
                                    <div className="icon"><span>5</span></div>
                                    <h4 className="title">Interview Training</h4>
                                </div>
                                <div className="icon-box line">
                                    <div className="icon"><span>6</span></div>
                                    <h4 className="title">Placement Cell</h4>
                                </div>
                                <div className="icon-box line">
                                    <div className="icon"><span>7</span></div>
                                    <h4 className="title">Multiple Interviews</h4>
                                </div>
                                <div className="icon-box line">
                                    <div className="icon"><span>8</span></div>
                                    <h4 className="title">Selection Process</h4>
                                </div>
                                <div className="icon-box">
                                    <div className="icon"><span>9</span></div>
                                    <h4 className="title">Get Offer Letter</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>
  );
}

export default Placements;
