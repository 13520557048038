import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import React from "react";
function PageNotFound() {
    return (
    <div className="application-thanks">
      <Helmet>
        <title>404 Page Not Found | Senfine Academy</title>
        <meta name="description" content="Senfine academy is the perfect place to learn new skills and advance your career. With our online courses, you can get certified in your chosen field quickly and easily. Apply now!"/>
        <meta name="keywords" content="404 Page Not Found"/>
        <link rel="canonical" href="https://www.senfineacademy.com/404-page-not-found"/>
      </Helmet>
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(/assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>404 Page Not Found</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Page Not Found</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="/assets/img/form-img.png" loading="lazy" alt="404 Page Not Found" />
        </div>
      </div>
      <div className="login-area default-padding">
        <div className="container">
            <div className="row reg-area">
                <div className="col-md-6 col-md-offset-3 reg-form">
                    <div className="form-box text-center">    
                        <i className="fa fa-3x fa-exclamation-circle text-danger" aria-hidden="true" style={{marginBottom:'20px'}}></i>
                        <h3>404 Page Not Found</h3>
                        <p>The page you are looking for is not found.</p>
                        <Link className="btn btn-info" to="/" style={{margin: '5px'}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back to Home</Link> 
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>
    );
};
 
export default PageNotFound;