import React from "react";
import { useState, useEffect } from 'react';
import jQuery from 'jquery';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import DataTable from 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RotatingLines } from 'react-loader-spinner';

import "../pages/Jobs.css";

function Jobs() {
    
    const [selectedJob, setSelectedJob] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const handleApplyClick = (job) => {
        console.log("Selected Job:", job);
        setSelectedJob(job);
    };
    
    useEffect(() => {
      const fetchJob = async () => {
        setIsLoading(true);
        try {
          const { data } = await axios.get(`/all-jobs`);
          setTableData(data.jobs);
        } catch (error) {
          Swal.fire({
            text: 'Error! Please try again.',
            icon: 'error',
          });
        } finally {
          setIsLoading(false);
        }
      };
    
      fetchJob();
    }, []);
    
    useEffect(() => {
      if (tableData && tableData.length > 0) {
        const table = jQuery("#jobsTable").DataTable({
          responsive: true,
        });
        
        jQuery('#jobsTable tbody').on('click', '.apply-btn', function () {
            const jobId = jQuery(this).data('job-id');
            const selectedJob = tableData.find(job => job.id === jobId);
    
            if (selectedJob) {
                setSelectedJob(selectedJob);
            }
        });
    
        return () => {
          table.destroy(true);
          jQuery('#jobsTable').off('click', '.apply-btn');
        };
      }
    }, [tableData]);
    
    let navigate = useNavigate();
      function handleSubmit(event) {
         event.preventDefault();
         const formData = new FormData(event.target);
         jQuery(".page-loader").fadeIn(300);
         jQuery('.senfine-modal').fadeOut(300);
         jQuery('.senfine-modal').removeClass('in');
         jQuery('body').removeClass('modal-open');
         jQuery('body').css('padding-right', '');
         jQuery('.modal-backdrop.in').remove();
         axios.post('/store-job-enquiry', formData)
         .then(({data})=>{
            jQuery(".page-loader").fadeOut(300);
            event.target.reset();
            if(data.status === 200){
              Swal.fire({
                    title:'Thank you',
                    text:'You have successfully applied for the '+selectedJob.position+' position in '+selectedJob.company+'. We will get back to you soon.',
                    icon:"success"
                })
            }
            else if(data.status === 201){
                Swal.fire({
                    title:'Error',
                    text:'Please fill required fields',
                    icon:"error"
                })
            }
            else{
                Swal.fire({
                    title:'Error',
                    text: 'Please try again later.',
                    icon:"error"
                })
            }
         })
         .catch(error => {
            jQuery(".page-loader").fadeOut(300);
            if (error.response && error.response.status === 500) {
                Swal.fire({
                    title:'Internal Server Error',
                    text: 'Please try again later.',
                    icon: "error"
                });
            } else {
                Swal.fire({
                    title:'An unexpected error occurred',
                    text: 'Please try again.',
                    icon: "error"
                });
            }
        });
      }
      
    const formatSalary = (salary) => {
        return salary.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    };
    
  return (
    <div className="jobs">
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Top Job Opportunities</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Jobs</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="assets/img/features-img.png" loading="lazy" alt="Senfine Jobs" />
        </div>
      </div>
      <div className="about-area default-padding">
      {isLoading ? (
        <div className="row">
        <div className="col-md-12 text-center">
            <RotatingLines
               visible={true}
               height="40"
               width="40"
               strokeColor="#0476bc"
               strokeWidth="4"
               animationDuration="0.75"
               ariaLabel="Loading"
            />
            <p>Please wait...</p>
        </div>
        </div>
        ) : (
        <div className="container">
            <div className="row">
                <div className="placements-info">
                    <div className="col-md-12 info">
                        <h2>Jobs</h2>
                        <table id="jobsTable" className="display">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Position</th>
                              <th>Company</th>
                              <th>Location</th>
                              <th>Salary</th>
                              <th>Experience</th>
                              <th>Posted On</th>
                              <th>Application</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData && tableData.length > 0 && tableData.map((job, index) => (
                            <tr key={job.id}>
                                <td>{index + 1}</td>
                                <td>{job.position}</td>
                                <td>{job.company}</td>
                                <td>{job.location}</td>
                                <td><span className="badge" style={{fontWeight: "500"}}><i className="far fa-money-bill-alt"></i> Upto ₹{formatSalary(job.salary)} a year</span></td>
                                <td>{job.experience}</td>
                                <td>{job.posted_date}</td>
                                <td>
                                    <button
                                        className="btn btn-warning btn-sm apply-btn"
                                        data-target="#senfineCareers"
                                        data-toggle="modal"
                                        data-job-id={job.id}
                                    >
                                        <i className="fa fa-check" aria-hidden="true"></i> Apply
                                    </button>
                                </td>
                            </tr>
                            ))}
                          </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        )}
      </div>
      <div id="senfineCareers" className="modal senfine-modal fade" role="dialog">
            <div className="modal-dialog modal-sm">

                <div className="modal-content reg-area">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                    <h4 className="modal-title">Apply for {selectedJob ? selectedJob.position : ''}</h4>
                </div>
                <div className="form-labels">
                    <p><b>Position: </b>{selectedJob ? selectedJob.position.slice(0, 35).trim() + (selectedJob.position.length > 35 ? '...' : '') : ''}</p>
                    <p><b>Company: </b>{selectedJob ? selectedJob.company.slice(0, 35).trim() + (selectedJob.company.length > 35 ? '...' : '') : ''}</p>
                </div>
                <div className="modal-body reg-form">
                    <form onSubmit={handleSubmit} autoComplete="off" encType="multipart/form-data">
                        <div className="row">
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="name">Name <span className="text-danger">*</span></label>
                                    <input id="name" className="form-control" name="name" placeholder="Enter Full Name" type="text" required />
                                    <input className="hidden" name="job_id" value={selectedJob ? selectedJob.id : ''} type="hidden" />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="mobile">Mobile <span className="text-danger">*</span></label>
                                    <input id="mobile" className="form-control mobile" name="mobile" placeholder="Enter Mobile Number" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="email">Email <span className="text-danger">*</span></label>
                                    <input id="email" className="form-control" name="email" placeholder="Enter Email Address" type="email" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="city">City <span className="text-danger">*</span></label>
                                    <input id="city" className="form-control" name="city" placeholder="Enter City" type="text" required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="resume">Resume <span className="text-danger">*</span></label>
                                    <input id="resume" className="form-control" name="resume" placeholder="Upload Resume" type="file" accept="image/*,application/msword,application/pdf,.pdf,.doc,.docx" required />
                                </div>
                            </div>
                            <div className="col-md-offset-3 col-xs-offset-3 col-md-6 col-xs-6">
                                <div className="form-group">
                                    <button className="btn btn-primary effect" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Jobs;
