import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { useState, useEffect } from 'react';
import Select from 'react-select';
import countryJs  from '../lib/Country';
import { Helmet } from "react-helmet";
import DatePicker from 'react-date-picker';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap-fileinput';
import 'bootstrap-fileinput/css/fileinput.min.css';
import "../pages/ApplicationForm.css";
import axios from 'axios';
import jQuery from 'jquery';
import Swal from 'sweetalert2';

function ApplicationForm() {
   
   const [courseOptions, setCourse] = useState([]);
   const fetchProduct = async () => {
      await axios.get(`/all-app-courses`).then(({data})=>{
          setCourse(data.appcourses)
      })
    }

   const [value, onChange] = useState(new Date());
   
    const [courseDurationOptions, setCourseDurationOptions] = useState([
      { value: '3 Months', label: '3 Months' },
      { value: '6 Months', label: '6 Months' }
    ])
   
    const [durationSelectedValue, setDurationSelectedValue] = useState([]);
   
    function selectedCourse (course) {
      if (course.value === 'Soft Skills Training') {
        setCourseDurationOptions([
            { value: '1 Month', label: '1 Month' },
            { value: '2 Months', label: '2 Months' }     
        ])
        setDurationSelectedValue([]);
      }
      else
      {
        setCourseDurationOptions([
            { value: '3 Months', label: '3 Months' },
            { value: '6 Months', label: '6 Months' }
        ])
        setDurationSelectedValue([]);
      }
    }
    
    function selectedCourseDuration (event) {
      setDurationSelectedValue([]);
      setDurationSelectedValue({
         value: event.value,
         label: event.value
       })
    }
   
   
   const genderOptions = [
      { value: 'Male', label: 'Male' },
      { value: 'Female', label: 'Female' },
      { value: 'Other', label: 'Other' }
   ]
   const relationOptions = [
      { value: 'Father', label: 'Father' },
      { value: 'Mother', label: 'Mother' },
      { value: 'Guardian', label: 'Guardian' }
   ]

   let navigate = useNavigate();
      function ApplicationSubmit(event) {
         event.preventDefault();
         const formData = new FormData(event.target);
         jQuery(".page-loader").fadeIn(300);
         axios.post('/store-application-enquiry', formData)
         .then(({data})=>{
            jQuery(".page-loader").fadeOut(100);
            event.target.reset();
            if(data.status === 200){
              jQuery(window).scrollTop(0);
              navigate('/application-thanks');
            }
            else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields',
                    icon:"error"
                })
            }
            else{
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
            }
         })
       }
  
   const [countries, setCountry] = useState('')
   const [states, setStates] = useState([])
   const [stateValue, setStateValue] = useState([]);
   const [countries1, setCountry1] = useState('')
   const [states1, setStates1] = useState([])
   const [stateValue1, setStateValue1] = useState([]);
   const [countries2, setCountry2] = useState('')
   const [countryValue, setCountryValue] = useState('');
   const [stateValue11, setStateValue11] = useState('');
   const [countrySelectedValue, setCountrySelectedValue] = useState([]);
   const [stateSelectedValue, setStateSelectedValue] = useState([]);
   const url = window.location.pathname.split('/').pop();

   useEffect(()=>{
      fetchProduct();
      const countryArray = [];
         var country1 = countryJs.country_arr;
         for (var i = 0; i < country1.length; i++) {
           countryArray.push({
             value: country1[i],
             label: country1[i],
             key:[i+1]
           });
         }
      setCountry(countryArray);

  },[url])
   // presentAddress
   function selectCountry (event) {
      setCountryValue(event.value)
        setStateValue([])
        const state_arr = countryJs.s_a[event.key].split("|"); 
        const stateArray = [];
        for (var i = 0; i < state_arr.length; i++) {
          stateArray.push({
          value: state_arr[i], 
          label: state_arr[i]
          });
        }
        setStates(stateArray)
   }
   function selectRegion (val) {
      setStateValue11(val.value)
        if(val.value !== '' && val.value !== null){
          setStateValue(val)
        } else {
          setStateValue([])
        }
   }
   // permanentAddress
   function selectCountry1 (event) {
      setStateSelectedValue([])
      setCountrySelectedValue({
         value: event.value,
         label: event.value
       })
      setStateValue1([])
      const state_arr1 = countryJs.s_a[event.key].split("|"); 
      const stateArray1 = [];
      for (var i = 0; i < state_arr1.length; i++) {
        stateArray1.push({
        value: state_arr1[i], 
        label: state_arr1[i]
        });
      }
      setStates1(stateArray1)
   }
   function selectRegion1 (val) {
      setStateSelectedValue({
         value: val.value, 
         label: val.value
      })
      if(val.value !== '' && val.value !== null){
        setStateValue1(val)
      } else {
        setStateValue1([])
      }
   }
   // nationality
   const [countries2SelectedValue, setCountry2SelectedValue] = useState({
      label: 'India',
      value: 'India'
   });
   // guardian
   jQuery("#guardian").hide();
   function selectedGuardian (relation) {
      if (relation.value === 'Guardian') {
         jQuery("#guardian").show();
         jQuery('#guardianid').attr('required', true);
      }
      else {
         
         jQuery("#guardian").hide();
         jQuery('#guardianid').attr('required', false);
      }
   }
   
   // same address check
   const handleSameAddressChange = (event) => {
      if (event.target.checked) {
         setCountrySelectedValue({
            value: countryValue,
            label: countryValue
          })
         setStateSelectedValue({
            value: stateValue11,
            label: stateValue11
          })
      } else {
         setCountrySelectedValue([]);
         setStateSelectedValue([]);
         setStates1([])
      }
   };

   // SameAddress
   jQuery(function () {
      jQuery("#SameAddress").click(function () {
         if (jQuery(this).is(":checked")) {            
            jQuery('#district1').val(jQuery('#district').val());
            jQuery('#pin1').val(jQuery('#pin').val());
            jQuery('#permanent_address').val(jQuery('#present_address').val());
            jQuery('#district1').css({'pointer-events':'none','background-color': '#eee'});
            jQuery('#country1 .css-13cymwt-control').css({'pointer-events':'none','background-color': '#eee'});
            jQuery('#state1 .css-13cymwt-control').css({'pointer-events':'none','background-color': '#eee'});
            jQuery('#pin1').css({'pointer-events':'none','background-color': '#eee'});
            jQuery('#permanent_address').css({'pointer-events':'none','background-color': '#eee'});
         } else {
            jQuery('#district1').val('');
            jQuery('#pin1').val('');
            jQuery('#permanent_address').val('');
            jQuery('#district1').css({'pointer-events':'auto','background-color': '#fff'});
            jQuery('#country1 .css-13cymwt-control').css({'pointer-events':'auto','background-color': '#fff'});
            jQuery('#state1 .css-13cymwt-control').css({'pointer-events':'auto','background-color': '#fff'});
            jQuery('#pin1').css({'pointer-events':'auto','background-color': '#fff'});
            jQuery('#permanent_address').css({'pointer-events':'auto','background-color': '#fff'});
         }
      });
   });

   // Capitalize
   jQuery('#name,#religion,#caste,#district,#district1,#present_address,#permanent_address,#pname,#guardianid,#poccupation,#tenth_board,#twelfthboardid,#degreestreamid,#degreeuniversityid,#pgstreamid,#pguniversityid').keyup(function(){
   if(jQuery(this).val().length>0 && jQuery(this).val().length<5){
      jQuery(this).val(jQuery(this).val().charAt(0).toUpperCase()+jQuery(this).val().substr(1));
   }
   });

   useEffect(()=>{
      // presentAddress
      const countryArray = [];
           var country1 = countryJs.country_arr;
           for (var i = 0; i < country1.length; i++) {
             countryArray.push({
               value: country1[i],
               label: country1[i],
               key:[i+1]
             });
           }
      setCountry(countryArray);
      // permanentAddress
      const countryArray1 = [];
           var country11 = countryJs.country_arr;
           for (var i = 0; i < country11.length; i++) {
             countryArray1.push({
               value: country11[i],
               label: country11[i],
               key:[i+1]
             });
           }
      setCountry1(countryArray1);
      // nationality
      const countryArray2 = [];
           var country2 = countryJs.country_arr;
           for (var i = 0; i < country2.length; i++) {
             countryArray2.push({
               value: country2[i],
               label: country2[i],
               key:[i+1]
             });
           }
      setCountry2(countryArray2);

   },[url])

  return (
    <div className="application-form">
      {/* <Helmet>
        <title>Online Application Form | Senfine Academy</title>
        <meta name="description" content="Senfine academy is the perfect place to learn new skills and advance your career. With our online courses, you can get certified in your chosen field quickly and easily. Apply now!"/>
        <meta name="keywords" content="Apply online, Online form, Online application form"/>
        <link rel="canonical" href="https://www.senfineacademy.com/application-form"/>
      </Helmet> */}
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Application Form</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Application Form</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="assets/img/form-img.png" loading="lazy" draggable="false" alt="Application Form" />
        </div>
      </div>
      <div className="login-area default-padding application-area">
        <div className="container">
            <div className="row reg-area">
            <div className="col-md-10 col-md-offset-1 reg-form appl-form">
                <div className="form-box">
                     <div className="form-head">
                        <h4>Senfine Application form</h4>
                     </div>
                    <div className="form-content">      
                        <form onSubmit={ApplicationSubmit} className="form contact-form text-left stepwizard-form">
                        <div className="row">
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                     <h4>Student's details</h4>
                                  </div>
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                     <label>Full Name *</label>
                                     <input type="text" name="name" className="form-control" placeholder="Name as per the 10th certificate" required id="name"/>
                                  </div>
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                     <label>Email address *</label>
                                     <input type="email" name="email" className="form-control" placeholder="Email" required/>
                                  </div>
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                     <label>Phone number *</label>
                                     <input type="text" name="phone" id="phone" className="form-control EnquiryMobile" placeholder="Phone" required/>
                                  </div>
                                  <div className="form-group col-md-4 col-sm-6 col-xs-6">
                                     <label>Date of Birth *</label>
                                     <DatePicker onChange={onChange} name="dob" value={value} required className="form-control" format="d-MM-y" maxDate={new Date()}/>
                                  </div>
                                  <div className="form-group col-md-4 col-sm-6 col-xs-6">
                                     <label>Gender *</label>
                                     <Select options={genderOptions} name="gender" placeholder="Select Gender" required/>
                                  </div>
                                  <div data-toggle-group="location">
                                     <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                        <label>Nationality *</label>
                                        <Select options={countries2} name="nationality" id="nationality" placeholder="Select Country" value={countries2SelectedValue}  required/>
                                     </div>
                                  </div>
                               </div>
                               <div className="row">
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                     <hr/>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                     <h4>Parent / Guardian Information</h4>
                                  </div>
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                     <label>Parent's Name *</label>
                                     <input type="text" name="pname" className="form-control" placeholder="Parent's Name" required id="pname"/>
                                  </div>
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                     <label>Relationship *</label>
                                     <Select options={relationOptions} onChange={selectedGuardian} className="" required name="relationship" id="relation" placeholder="Select Relationship" />
                                  </div>
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                     <label>Parent's Number *</label>
                                     <input type="text" name="pnumber" className="form-control" placeholder="Parent's Contact Number" required/>
                                  </div>
                                  <div className="form-group col-md-12 col-sm-12 col-xs-12" id="guardian">
                                     <label>Mention the Relationship *</label>
                                     <input type="text" name="guardian_relation" id="guardianid" className="form-control" placeholder="Mention the Relationship" required/>
                                  </div>
                               </div>
                               <div className="row">
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                     <hr/>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                     <h4>Address</h4>
                                  </div>
                                <div className="">
                                    <div className="col-sm-6 col-xs-12">
                                          <h5>Present Address:</h5>
                                          <div className="row">
                                  <div data-toggle-group="location">
                                       <div className="form-group col-md-6 col-sm-6 col-xs-6">
                                           <label>Country *</label>
                                           <Select options={countries} onChange={selectCountry} name="country" id="country" className="" required placeholder="Select Country" />
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6 col-xs-6">
                                           <label>State *</label>
                                           <Select options={states} value={stateValue} onChange={selectRegion} name="state" id="state" className="" required placeholder="Select State" />
                                        </div>
                                  </div>
                                  <div className="form-group col-md-8 col-sm-8 col-xs-8">
                                     <label>District / City *</label>
                                     <input type="text"  name="district" className="form-control" placeholder="District / City" id="district" required/>
                                  </div>
                                  <div className="form-group col-md-4 col-sm-4 col-xs-4">
                                     <label>PIN *</label>
                                     <input type="number" minLength="5" maxLength="6" name="pin" id="pin" className="form-control" placeholder="PIN Code" required/>
                                  </div>
                                  
                                  <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                     <label>Address *</label>
                                     <textarea className="form-control" name="present_address" id="present_address" rows="2" placeholder="Type your address..." required></textarea>
                                  </div>
                                    <div className="form-group col-md-12 col-sm-12 col-xs-12" id="same">
                                     <div className="checkbox">
                                        <label className="form-check">
                                            <input className="form-check-input" type="checkbox" id="SameAddress" onChange={handleSameAddressChange}/> Is your Permanent address same as Present address?
                                        </label>
                                     </div>
                                  </div>
                                  </div>
                                  </div>
                                  
                                  <div className="col-sm-6 col-xs-12">
                                  <h5>Permanent Address:</h5>
                                  <div className="row">
                                  <div data-toggle-group="location">
                                  <div className="form-group col-md-6 col-sm-6 col-xs-6">
                                        <label>Country *</label>
                                        <Select options={countries1} onChange={selectCountry1} value={countrySelectedValue} name="country1" id="country1" className="" required placeholder="Select Country" />
                                     </div>
                                     <div className="form-group col-md-6 col-sm-6 col-xs-6">
                                        <label>State *</label>
                                        <Select options={states1} onChange={selectRegion1} value={stateSelectedValue} name="state1" id="state1" className="" required placeholder="Select State" />
                                     </div>
                                  </div>
                                  
                                  <div className="form-group col-md-8 col-sm-8 col-xs-8">
                                     <label>District / City *</label>
                                     <input type="text"  name="district1" className="form-control" placeholder="District / City" id="district1" required/>
                                  </div>
                                  <div className="form-group col-md-4 col-sm-4 col-xs-4">
                                     <label>PIN *</label>
                                     <input type="number" minLength="5" maxLength="6" name="pin1" id="pin1" className="form-control" placeholder="PIN Code" required/>
                                  </div>
                                  <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                     <label>Address *</label>
                                     <textarea className="form-control" name="permanent_address" id="permanent_address" rows="2" placeholder="Type your address..." required></textarea>
                                  </div>
                                  </div>
                                  </div>
                                  
                                </div>
                    
                               </div>
                               <div className="row">
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                     <hr/>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                     <h4>Select the course</h4>
                                  </div>
                                  <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                     <label>Course Preferred *</label>
                                     <Select options={courseOptions} onChange={selectedCourse} className="" id="course-options" required name="course" placeholder="Select Course" />
                                  </div>
                                  <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                     <label>Duration *</label>
                                     <Select options={courseDurationOptions} onChange={selectedCourseDuration} value={durationSelectedValue} className="" id="duration" required name="duration" placeholder="Select Duration" />
                                  </div>
                                  
                                  <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                     <hr/>
                                     <button type="submit" className="btn btn-success submitBtn" style={{width:"auto"}}><i className="fa fa-check" aria-hidden="true"></i> Submit</button>
                                  </div>
                               </div>
                            </form>
                        </div>  
                </div>
                </div>
            </div>
        </div>
      </div>

    </div>
  );
}

export default ApplicationForm;
