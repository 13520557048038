import React from "react";
import { Link } from "react-router-dom";
import 'swiper/element/bundle';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Helmet } from "react-helmet";

function Testimonials() {
  return (
    <div className="testimonials">
      {/* <Helmet>
        <title>Student Testimonials | Senfine Academy</title>
        <meta name="description" content="Senfine academy offers a comprehensive set of features to help you learn faster and smarter. Our courses are designed to be engaging, interactive, and tailored to your individual learning style."/>
        <meta name="keywords" content="data mining with python, data analytics, search engine optimization, social media optimization, ai start-ups and innovations, certification in sales management, professional certification in graphic design, ielts / oet, communicative english, certification in educational psychology, certification in child psychology, certification in clinical psychology, big data, cybersecurity & internet of things (iot), digital marketing & communication, cloud computing"/>
        <link rel="canonical" href="https://www.senfineacademy.com/testimonials" />
      </Helmet> */}
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Student Testimonials</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Student Testimonials</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="assets/img/contact-us-img.png" loading="lazy" alt="Student Testimonials" />
        </div>
      </div>
      <div className="testimonials-area default-padding">
        <div className="container">
            <div className="row">
                    <div className="site-heading text-left">
                        <div className="col-md-12">
                            <h2>Inspired Achievements</h2>
                        </div>
                    </div>
                    <div className="clients-review-carousel">
                    <Swiper id="clients-review-carousel"
                        spaceBetween={10}
                        pagination={{clickable: "true"}}
                        breakpoints={{ 320: { slidesPerView: 1,}, 1024: { slidesPerView: 3,}}}
                        loop={true}
                        autoplay={{enable: "true", delay: "2000", pauseOnMouseEnter: "true",}}
                    >
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="assets/img/testimonials/avatar0.jpg" loading="lazy" alt="Nikhil P S" />
                                    </div>
                                    <div className="others">
                                        <h4>Nikhil</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    My immense experience started on the first day, when I realised I was not just in a academy but in the corporate world. In addition to the wonderful teaching, a variety of activities were conducted to help us discover our hidden talents, build our strengths, and learn how to work as a team to overcome our weaknesses. There is a good learning environment that is enjoyable and friendly, with knowledgeable teaching staff.
                                </p>
                                </div>
                                
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="assets/img/testimonials/senfine-testimonial-chandanakoti.jpg" loading="lazy" alt="Chandana Koti" />
                                    </div>
                                    <div className="others">
                                        <h4>Chandana</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    I am happy that I chose Senfine Academy to complete my AI & MI coursework because all of the faculty members provide excellent care for their students both academically and extra curricularly. The college's placement assistants are to be commended for advancing my careers because they give all students effective pre-placement training that prepares us for the workplace and gives us all the chance to realise our dreams.
                                </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="assets/img/testimonials/y.png" loading="lazy" alt="" />
                                    </div>
                                    <div className="others">
                                        <h4>Yashuraj</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    As far as the Academic is concerned, the Senfine syllabus provides an excellent foundation for a smooth take off in my careers. After I joined Senfine, I gained extensive knowledge about management and business, as well as a path to follow as I built my career and became an authority in finance and human resources. The distinctive characteristics of Senfine Academy set it apart from other institutes.
                                </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="assets/img/testimonials/k.png" loading="lazy" alt="" />
                                    </div>
                                    <div className="others">
                                        <h4>Komal</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="far fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-scrollable">
                                <p>
                                    Senfine Academy has been a great contributor to the development of my personality. I have established my leadership, time management and team skills and have also been able to advance these skills to the whole new augment level. The infrastructure of Senfine is one of the finest in the Bangalore is what stands out the most. The best thing about this academy is the cross-culture interaction between students from various parts of the country.
                                </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="assets/img/testimonials/avatar0.jpg" loading="lazy" alt="" />
                                    </div>
                                    <div className="others">
                                        <h4>Tanuja</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <p>The quality of training was very impressive, faculty is very helpful and available all the time for doubt clearing. Thank you.</p>
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="single-item">
                        <div className="item">
                            <div className="info">
                                <div className="user-info">
                                    <div className="thumb">
                                        <img className="circle" src="assets/img/testimonials/avatar0.jpg" loading="lazy" alt="" />
                                    </div>
                                    <div className="others">
                                        <h4>Santosh</h4>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <p>I recently enrolled in the Digital Marketing Course at Senfine Academy and I am extremely impressed with the quality of the course. The course content is comprehensive, covering all aspects of digital marketing from SEO to social media advertising. The instructors are knowledgeable and experienced, making complex concepts easy to understand. I particularly appreciated the hands-on approach of the course, where we got to apply what we learned through practical exercises and real-time projects. Overall, I would highly recommend this course to anyone looking to build a career in digital marketing. Senfine Academy is truly a great place to learn and grow!</p>
                            </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        </Swiper>
                    </div>
            </div>
        </div>
    </div>

    </div>
  );
}

export default Testimonials;
