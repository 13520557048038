import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import react, { useEffect, useState } from 'react';
import "./BlogDetails.css";
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';

function BlogDetails() {
    const params = useParams();
    const [allblogs, setAllBlogs] = useState([])
    const [thisBlog, setThisBlog] = useState([])
    const [thisPrev, setThisPrev] = useState([])
    const [thisNext, setThisNext] = useState([])
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

    useEffect(()=>{
        fetchProduct(params.id);
    },[params.id])
    
    const fetchProduct = async () => {
        setIsLoading(true)
        await axios.get(`/blog-page-data/${params.id}`).then(({data})=>{
          setAllBlogs(data.blogs)
          setThisBlog(data.blog)
          setCourses(data.trendcourses)
          setThisPrev(data.previous)
          setThisNext(data.next)
          setIsLoading(false)
        }).catch(({response:{data}})=>{
            setIsLoading(false)
            return navigate("404");
        })
      }

     
    function blogDate(par){
            const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            const today = new Date(par);
            const month = months[today.getMonth()];
            const year = today.getFullYear();
            const date = today.getDate();
            return date+ ' ' +month+', ' +year;
    }
    function blogDay(par){
            const today = new Date(par);
            const date = today.getDate();
            return date;
    }
    function blogMonth(par){
            const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            const today = new Date(par);
            const month = months[today.getMonth()];
            return month;
    }
    function blogYear(par){
            const today = new Date(par);
            const year = today.getFullYear();
            return year;
    }
    function ArticleDate(par){
            const months = ["01","02","03","04","05","06","07","08","09","10","11","12"];
            const today = new Date(par);
            const month = months[today.getMonth()];
            const year = today.getFullYear();
            const date = today.getDate();
            const hour = today.getHours();
            const minute = today.getMinutes();
            const second = today.getSeconds();
            return year+'-'+month+'-'+date+'T'+hour+':'+minute+':'+second+'+05:30';
    }
  return (
	  thisBlog!==null?
    <div className="blog-details">
    {/* <Helmet>
        <title>{thisBlog.title}</title>
        <meta name="description" content={thisBlog.meta_des}/>
        <meta name="keywords" content={thisBlog.meta_key}/>
        <meta property="article:publisher" content="https://www.facebook.com/senfineacademy" />
        <meta property="article:author" content="https://www.facebook.com/senfineacademy" />
        <meta property="article:section" content={thisBlog.title} />
        <meta property="article:published_time" content={ArticleDate(new Date(thisBlog.created_at))} />
        <meta property="article:modified_time" content={ArticleDate(new Date(thisBlog.updated_at))} />
        <meta property="og:title" content={thisBlog.title} />
        <meta property="og:url" content={`https://www.senfineacademy.com/blogs/${thisBlog.slug}`} />
        <meta property="og:description" content={thisBlog.meta_des} />
        <meta property="og:updated_time" content={ArticleDate(new Date(thisBlog.updated_at))} />
        <meta property="og:image" content={`https://www.senfineacademy.com/storage/blogs/${thisBlog.image}`} />
        <meta property="og:image:secure_url" content={`https://www.senfineacademy.com/storage/blogs/${thisBlog.image}`} />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
        <meta property="og:image:alt" content={thisBlog.title} />
      </Helmet> */}
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(/assets/img/inner-banner-bg.jpg'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>{thisBlog.title}</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/blogs">Blogs</Link></li>
                        <li className="active">{thisBlog.title}</li>
                    </ul>
                </div>
            </div>
        </div>
        {/* <div className="banner-top-img">
            <img className="img-responsive" src="/assets/img/courses-img.png" loading="lazy" />
        </div> */}
      </div>
      <div className="blog-area full-blog right-sidebar single-blog full-blog default-padding">
        <div className="container">
            {isLoading ? (
             <div className="row">
             <div className="col-md-12 text-center">
                 <RotatingLines
                    visible={true}
                    height="40"
                    width="40"
                    strokeColor="#0476bc"
                    strokeWidth="4"
                    animationDuration="0.75"
                    ariaLabel="Loading"
                 />
                 <p>Please wait...</p>
             </div>
             </div>
            ) : (
            <div className="row">
                <div className="blog-items">
                <div className="blog-content col-md-8">
                <div className="item-box">
                   <div className="">
                      <div className="thumb">
                         <img src={`/storage/blogs/${thisBlog.image}`} loading="lazy" draggable="false" alt={thisBlog.title} />
                         <div className="date">
                            <h4><span>{blogDay(new Date(thisBlog.updated_at))}</span> {blogMonth(new Date(thisBlog.updated_at))}, {blogYear(new Date(thisBlog.updated_at))}</h4>
                         </div>
                      </div>
                      <div className="info">
                      <div dangerouslySetInnerHTML={{ __html: thisBlog.content }} />
                      </div>
                      <div class="bottom-info text-center">
                        <p>Discover your way to success at Senfine Academy, where we offer various courses to enhance your skills and career prospects. Contact us for details on admissions and take the first step towards your goals.</p>
                        <div class="cta-btns">
                            <a class="btn btn-sm btn-danger" href="#senfineApply" data-toggle="modal" data-target="#senfineApply"><i class="fa fa-check-circle" aria-hidden="true"></i> Apply Now</a>
                            <a class="btn btn-sm btn-warning" href="tel:+919845412266"><i class="fa fa-phone" aria-hidden="true"></i> Call Now</a>
                            <a class="btn btn-sm btn-success" href="https://wa.me/919845412266?text=Senfine%20Academy%20Admission%20Enquiry%20"><i class="fab fa-whatsapp" aria-hidden="true"></i> WhatsApp us</a>
                        </div>
                      </div>
                      <div className="post-pagi-area">
                      {(() => {
                         if(thisNext){
                            return(
                             <Link  to={`/blogs/${thisNext.slug}`} className="btn"><i className="fas fa-angle-double-left"></i> Previous Blog</Link>
                             );
                            }
                             else if(thisNext==null){ return (<Link  to="#" className="btn disabled"><i className="fas fa-angle-double-left"></i> Previous Blog</Link>)
                            }
                        })()}
                      {(() => {
                        if(thisPrev){
                            return( 
                            <Link  to={`/blogs/${thisPrev.slug}`} className="btn">Next Blog <i className="fas fa-angle-double-right"></i></Link>
                            );
                           }
                            else if(thisPrev==null){ return (<Link  to="#" className="btn disabled">Next Blog <i className="fas fa-angle-double-right"></i></Link>)
                           }
                        })()}
                      </div>
                   </div>
                </div>

                </div>
                <div className="col-md-4">
                    <div className="sidebar">
                        <aside>
                            <div className="sidebar-item recent-post side-blogs">
                                <div className="title">
                                    <h4>Recent Blogs</h4>
                                </div>
                                {allblogs && allblogs.length > 0 && allblogs.map((blog) => {
                                    return (
                                <div className="item" key={`b${blog.id}`}>
                                    <div className="content">
                                        <div className="thumb">
                                            <Link to={`/blogs/${blog.slug}`}>
                                                <img src={`/storage/blogs/${blog.image}`} loading="lazy" draggable="false" alt={blog.title} />
                                            </Link>
                                        </div>
                                        <div className="info">
                                            <h4>
                                                <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
                                            </h4>
                                            <div className="meta">
                                                <i className="fas fa-calendar"></i> {blogDate(new Date(blog.updated_at))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    );
                                })}            
                            </div>
                            <div className="sidebar-item category">
                                <div className="title">
                                    <h4>Trending Courses</h4>
                                </div>
                                <div className="sidebar-info">
                                    <ul>
                                        {courses.map((course) => {
                                            return(
                                        <li key={`c${course.id}`}><Link to={`/courses/${course.slug}`}>{course.course}</Link></li>
                                        );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
                </div>
            </div>
            )}
        </div>
    </div>
    </div>
    :
	<h1> Can't connect to server ! </h1>
  );
}

export default BlogDetails;
