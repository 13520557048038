import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../pages/TermsConditions.css";

function TermsConditions() {
  return (
    <div className="terms-and-conditions">
      {/* <Helmet>
        <title>Payment Terms and Conditions | Senfine Academy</title>
        <meta name="description" content="Senfine Academy is an Edu-Tech company that provides certification courses in many segments. Our main aim is to provide high-quality education and help students to boost their careers."/>
        <meta name="keywords" content="data mining with python, data analytics, search engine optimization, social media optimization, ai start-ups and innovations, certification in sales management, professional certification in graphic design, ielts / oet, communicative english, certification in educational psychology, certification in child psychology, certification in clinical psychology, big data, cybersecurity & internet of things (iot), digital marketing & communication, cloud computing"/>
        <link rel="canonical" href="https://www.senfineacademy.com/terms-and-conditions" />
      </Helmet> */}
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Payment Terms & Conditions</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Terms & Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="assets/img/form-img.png" loading="lazy" alt="Terms and Conditions" />
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container">
            <div className="row">
                <div className="about-info">
                    <div className="col-md-12 info">
                        <p><i>Please read these terms carefully before using the online payment facility. Using the online payment facility on this website indicates that you accept these terms. If you do not accept these terms do not use this facility.</i></p>
                        <h3>ALL PAYMENTS ARE SUBJECT TO THE FOLLOWING CONDITIONS:-</h3>
                        <p>The description of courses are specific to you (i.e. Programme Name, start and end date etc.) when you log in with your unique password. Normally payment is required in advance (i.e. before you commence your programme).</p>
                        <p><b>All Fees quoted are in Indian Rupees. Senfine Academy reserves the right to change the fees at any time.</b></p>
                        <ul>
                            <li>Your payment will normally reach the Senfine Academy account to which you are making a payment within two working days.</li>
                            <li>We cannot accept liability for a payment not reaching the correct Senfine Academy account due to you quoting an incorrect account number or incorrect personal details. Neither can we accept liability if payment is refused or declined by the credit/debit card supplier for any reason.</li>
                            <li>If the card supplier declines payment the Senfine Academy is under no obligation to bring this fact to your attention. You should check with your bank/credit/debit card supplier that payment has been deducted from your account.</li>
                            <li>In no event will the Senfine Academy be liable for any damages whatsoever arising out of the use, inability to use, or the results of use of this site, any websites linked to this site, or the materials or information contained at any or all such sites, whether based on warranty, contract, tort or any other legal theory and whether or not advised of the possibility of such damages.</li>
                        </ul>
                        <h3>Refund Policy</h3>
                        <ul>
                            <li>If the Customer leaves the Senfine Academy before they complete their Programme, there shall be no entitlement to a refund of Tuition Fees.</li>
                            <li>Refunds, if applicable, at the discretion of the Management, will only be made to the debit/credit card used for the original transaction. For the avoidance of doubt nothing in this Policy shall require the Senfine Academy to refund the Fees (or part thereof) unless such Fees (or part thereof) have previously been paid.</li>
                        </ul>
                        <h3>Security</h3>
                        <ul>
                            <li>All payment details which are entered through this payment gateway are encrypted when the Customer, or third party making payment, enters them. Communications to and from the Service Provider's site are encrypted.</li>
                            <li>Senfine Academy shall not be liable for any failure by the Customer or third party making payment of Fees to properly protect data from being seen on their screen by other persons or otherwise obtained by such persons, during the online payment process or in respect of any omission to provide accurate information in the course of the online payment process.</li>
                        </ul>
                        <h3>Service Provider</h3>
                        <ul>
                            <li>Online Payments of Fees can be made by Credit / Debit card or Netbanking. These payments are not collected by the Senfine Academy directly but by the Service Provider.</li>
                            <li>Once payment is received by the Service Provider in cleared funds, the Service Provider will contact the Senfine Academy to confirm the details of the successful payment made by the Customer, or third party making payment. On receipt of this confirmation the Senfine Academy will confirm to the Customer that the payment has been received and accepted by the Senfine Academy.</li>
                            <li>In the event that the Customer does not receive confirmation within 14 days of making the payment it is the responsibility of the Customer to check with the Senfine Academy that the payment has been accepted.</li>
                            <li>The Customer shall remain responsible for the Course Fees until such time as the confirmation as referred to above is received from the Senfine Academy in relation to each of these and any outstanding amounts owed to the Senfine Academy.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>
  );
}

export default TermsConditions;
