import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from 'axios';
import Swal from 'sweetalert2';

function Contact() {
    
    function ContactSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    axios.post('/store-contact-enuiry', formData)
    .then(({data})=>{
       event.target.reset();
       if(data.status === 200){
        Swal.fire({
            text:'Thank you!. We will get back to you shortly..',
            icon:"success"
        })
       }
       else if(data.status === 201){
           Swal.fire({
               text:'Please fill required fields',
               icon:"error"
           })
       }
       else{
           Swal.fire({
               text:'Error please try again later!',
               icon:"error"
            })
          }
        })
    }
  
  return (
    <div className="contact">
      {/* <Helmet>
        <title>Contact us for the Top Certification Courses in Bangalore</title>
        <meta name="description" content="Contact the best academic training course near Church Street, MG Road Bangalore. Our experienced team is dedicated to helping you reach your career goals."/>
        <meta name="keywords" content="data mining with python certification course, data analytics certification course, search engine optimization certification course, social media optimization certification course, graphic design certification course, big data certification course, cybersecurity & internet of things (IOT) certification course, digital marketing & communication certification course, cloud computing certification course, best academic course near mg road, best academic course in church street"/>
        <link rel="canonical" href="https://www.senfineacademy.com/contact-us" />
      </Helmet> */}
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(/assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Contact us for the Top Certification Courses in Bangalore</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Contact us</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="assets/img/contact-us-img.png" loading="lazy" alt="Contact us" />
        </div>
      </div>
      <div className="contact-info-area default-padding">
        <div className="container">
            <div className="row about-area">
                <div className="col-md-12 info">
                    <h2>Get In Touch With Us Through:</h2>
                </div>
            </div>
            <div className="row">
                <div className="contact-info flex-contact">
                    <div className="col-md-4 col-sm-4">
                        <div className="item">
                            <div className="icon">
                                <i className="fas fa-phone"></i>
                            </div>
                            <div className="info">
                                <h4>Call Us</h4>
                                <span><a href="tel:+919845412266">+91 9845412266</a></span><br/>
                                <span><a href="tel:+919606081454">+91 9606081454</a></span><br/>
                                <span><a href="tel:+918022223530"><b>Landline:</b> 080-22223530</a></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <div className="item">
                            <div className="icon">
                                <i className="fas fa-map-marker-alt"></i>
                            </div>
                            <div className="info">
                                <h4>Address</h4>
                                <span>No. 309, 3<sup>rd</sup> Floor, Brigade Gardens, Church Street, MG Road, Bangalore, Karnataka - 560001,<br/><b>Landmark:</b> Opposite to MG Road Metro Station </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <div className="item">
                            <div className="icon">
                                <i className="fas fa-envelope"></i>
                            </div>
                            <div className="info">
                                <h4>Mail Us</h4>
                                <span><a href="mailto:info@senfineacademy.com">info@senfineacademy.com</a><br/><a href="mailto:admin@senfineacademy.com">admin@senfineacademy.com</a></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="seperator col-md-12">
                    <span className="border"></span>
                </div>
                <div className="maps-form">
                    <div className="col-md-6 maps">
                        <h3>Location</h3>
                        <div className="google-maps">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.956004764239!2d77.60339591482202!3d12.974665790853892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17309396d1a7%3A0xfc58e4b2030be19!2sSenfine%20Academy!5e0!3m2!1sen!2sin!4v1672289959025!5m2!1sen!2sin" width="600" height="450" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">Online Certificate Program, Online Course with Certificate, Certification Courses for Students, Career Development Courses, Skill Development Courses Online</iframe>
                        </div>
                    </div>
                    <div className="col-md-6 form">
                        <div className="heading">
                            <h3>Let's Connect</h3>
                        </div>
                        <form onSubmit={ContactSubmit} className="contact-form">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="form-group">
                                        <input className="form-control" id="name" name="name" placeholder="Name" type="text" required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="form-group">
                                        <input className="form-control" id="email" name="email" placeholder="Email" type="email" required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="form-group">
                                        <input className="form-control" id="phone" name="phone" placeholder="Phone" type="text" required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="form-group comments">
                                        <textarea className="form-control" id="message" name="message" placeholder="Message" required></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <button className="btn btn-primary effect" type="submit" name="submit" id="submit">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>
  );
}

export default Contact;
