import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import React from "react";

function ApplicationThanks() {
  return (
    <div className="application-thanks">
      {/* <Helmet>
        <title>Thanks - Online Application Form | Senfine Academy</title>
        <meta name="description" content="Senfine academy is the perfect place to learn new skills and advance your career. With our online courses, you can get certified in your chosen field quickly and easily. Apply now!"/>
        <meta name="keywords" content="Apply online, Online form, Online application form"/>
        <link rel="canonical" href="https://www.senfineacademy.com/application-thanks"/>
      </Helmet> */}
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Thank you</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Thank you</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="assets/img/form-img.png" loading="lazy" draggable="false" alt="Application Form Thanks" />
        </div>
      </div>
      <div className="login-area default-padding">
        <div className="container">
            <div className="row reg-area">
                <div className="col-md-6 col-md-offset-3 reg-form">
                    <div className="form-box text-center">    
                        <i className="fa fa-3x fa-check-circle text-success" aria-hidden="true" style={{marginBottom:'20px'}}></i>
                        <h3>Thank you</h3>
                        <p>Thank you for submitting the Application. Our Admission Officer will Callback you shortly.</p>
                        <Link className="btn btn-info" to="/" style={{margin: '5px'}}><i className="fa fa-angle-left" aria-hidden="true"></i> Home</Link> 
                        {/*<a className="btn btn-success" href="/application-form-pdf/{id}" style={{margin: '5px'}}><i className="fa fa-file-pdf" aria-hidden="true"></i> Download PDF</a>*/}
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>
  );
}

export default ApplicationThanks;
