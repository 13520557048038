import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../pages/PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      {/* <Helmet>
        <title>Privacy Policy | Senfine Academy</title>
        <meta name="description" content="Find all the information about Senfine's Website Details, Data Privacy Policy, and changes to our privacy policy."/>
        <meta name="keywords" content="data mining with python, data analytics, search engine optimization, social media optimization, ai start-ups and innovations, certification in sales management, professional certification in graphic design, ielts / oet, communicative english, certification in educational psychology, certification in child psychology, certification in clinical psychology, big data, cybersecurity & internet of things (iot), digital marketing & communication, cloud computing"/>
        <link rel="canonical" href="https://www.senfineacademy.com/privacy-policy" />
      </Helmet> */}
    
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(assets/img/inner-banner-bg.jpg)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Privacy Policy</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="assets/img/form-img.png" loading="lazy" alt="Privacy Policy" />
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container">
            <div className="row">
                <div className="about-info">
                    <div className="col-md-12 info">
                        <p>This Privacy Policy applies to all of the products, services and websites offered by Senfine Academy. Sometimes, we may post product specific privacy notices or Help Centre materials to explain our products in more detail.</p>
                        <p>If you have any questions about this Privacy Policy, please feel free to contact us through our website or write to us at <a href="mailto:info@senfineacademy.com">info@senfineacademy.com</a></p>
                        <h3>Website Detail</h3>
                        <ul>
                            <li>Entire content of the website has been gathered to the best of our knowledge and belief, and the company endeavors to keep it up to date. In particular, we reserve the entire right to update and/or modify and/or remove this data anytime and without any prior notice.</li>
                            <li>Please note that the Senfine Academy holds no responsibility for incorrect or no longer correct statements, quotes or descriptions and thereby excludes liability for any losses resulting from such wrong information.</li>
                        </ul>
                        <p>Further, Senfine Academy has every right to bring about legal action on customers who misuse/ alter/ reproduce the content/ information available on the website</p>
                        <h3>Data Privacy Policy</h3>
                        <p>Our data privacy policy strictly conforms as per the regulations set down in accordance to the protection of data collected from people visiting our website. Senfine Academy treats all personal data obtained via the internet and our website carefully and confidentially so that no physical/mental harm is instilled up on the person, with regard to the collected data . Personal data that has possibly been known to us will be kept secret, privacy will be respected and information will be used exclusively for the purpose for which it was made known to us. Without seeking approval of the respective person, this data will neither be made accessible to third parties, nor transferred to them in any form or manner.</p>
                        <h3>Changes to our Privacy Policy</h3>
                        <p>Senfine Academy reserves the entire right to modify/ amend/ remove this privacy statement anytime and without any reason. Nothing contained herein creates or is intended to create a contract/ agreement between Senfine Academy and any user visiting the Senfine Academy website or providing identifying information of any kind.</p>
                        <h3><i>DND Policy</i></h3>
                        <p><i>If you wish to stop any further sms/ email alerts/ contacts from our side, all you need to do is to send an email at <a href="mailto:info@senfineacademy.com">info@senfineacademy.com</a> with your mobile numbers and you will be excluded from the alerts list.</i></p>
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>
  );
}

export default PrivacyPolicy;
