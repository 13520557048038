import React from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import '../pages/Courses.css';
import jQuery from 'jquery';
import { RotatingLines } from 'react-loader-spinner';
import Swal from 'sweetalert2';

function Courses() {
   const [courses, setCourses] = useState([]);  
   const [allcourses, setAllCourses] = useState([]);  
   const [trendingcourses, setTrendingCourses] = useState([]); 
   const [popularcourses, setPopularCourses] = useState([]); 
   const [featuredcourses, setFeaturedCourses] = useState([]);  
   const [isLoading, setIsLoading] = useState(false);

   useEffect(()=>{
    const fetchCourses = async () => {
        setIsLoading(true)
        await axios.get(`/all-courses`).then(({data})=>{
            setCourses(data.courses)
            setAllCourses(data.allcourses)
            setTrendingCourses(data.trendingcourses)
            setPopularCourses(data.popularcourses)
            setFeaturedCourses(data.featuredcourses)
            setIsLoading(false)
        }).catch(({response:{data}})=>{
            setIsLoading(false)
            Swal.fire({
                text:'Error!.',
                icon:"error"
            })
        })
      }
      fetchCourses();
    },[]);
    
  return (
    allcourses!==null?
    <div className="courses">
      {/* <Helmet>
        <title>Skill Development & Career Certification Program in Bangalore | Senfine Academy</title>
        <meta name="description" content="Senfine Academy offers certified courses in Bangalore for students and career seekers. Learn from industry experts and get access to cutting-edge tools & resources to develop the skills you need to succeed."/>
        <meta name="keywords" content="Data Mining With Python, Data Analytics, Search Engine Optimization (SEO), Social Media Optimization (SMO), AI Start-Ups And Innovations, Certification In Sales Management, Professional Certification In Graphic Design, IELTS/OET, Communicative English, Certification In Educational Psychology, Certification In Child Psychology, Certification In Clinical Psychology, Big Data, Cybersecurity & Internet Of Things (IoT), Digital Marketing & Communication, Cloud Computing"/>
        <link rel="canonical" href="https://www.senfineacademy.com/courses"/>
      </Helmet> */}
      
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(/assets/img/courses-banner-bg.webp)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Professional Certification Programs</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Courses</li>
                    </ul>
                </div>
            </div>
        </div>
      </div>

      <div className="popular-courses circle default-padding without-carousel course-loading">
        <div className="container course-loading-wrap">
            <div className="row">
                <div className="site-heading text-left">
                    <div className="col-md-6">
                        <h2>Certification Programs</h2>
                    </div>
                    <div className="col-md-6">
                        <ul className="filter-btns clearfix" id="filters">
                            <li className="active"><a href="#catAll" data-toggle="tab">All</a></li>
                            <li><a href="#catTrending" data-toggle="tab">Trending</a></li>
                            <li><a href="#catPopular" data-toggle="tab">Popular</a></li>
                            <li><a href="#catFeatured" data-toggle="tab">Featured</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
    {isLoading ? (
      <div className="row">
      <div className="col-md-12 text-center">
          <RotatingLines
             visible={true}
             height="40"
             width="40"
             strokeColor="#0476bc"
             strokeWidth="4"
             animationDuration="0.75"
             ariaLabel="Loading"
          />
          <p>Please wait...</p>
      </div>
      </div>
        ) : (
        <div className="row">
                <div className="col-md-12">
                  <div className="popular-courses-items tab-content">
                    <div className="row flex tab-pane fade in active" id="catAll">
                    {allcourses.map((course, index) => (
                        
                        <div className="col-sm-12 col-md-3" key={index}>
                        <div className="item">
                            <div className="thumb">
                                <Link to={`/courses/${course.slug}`}>
                                    <img src={`/storage/courses/${course.image}`} loading="lazy" draggable="false" alt={course.course} />
                                </Link>
                            </div>
                            <div className="info">
                                <Link to="#senfineCourseEnquiry" data-toggle="modal" data-target="#senfineCourseEnquiry" c-name={course.course} className="btn btn-theme btn-xsm scourse b2">Apply Now</Link>
                                <h4><Link to={`/courses/${course.slug}`}>{course.course}</Link></h4>
                                <p>
                                {course.institution}
                                </p>
                                <div className="author-info">
                                    <div className="others">
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                            <span> {course.rating}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                         
                    ))}
                    </div>
                    <div className="row flex tab-pane fade" id="catTrending">
                    {trendingcourses.map((course, index) => (
                        <div className="col-sm-12 col-md-3" key={index}>	
                        <div className="item">
                            <div className="thumb">
                                <Link to={`/courses/${course.slug}`}>
                                    <img src={`/storage/courses/${course.image}`} loading="lazy" draggable="false" alt={course.course} />
                                </Link>
                            </div>
                            <div className="info">
                                <Link to="#senfineCourseEnquiry" data-toggle="modal" data-target="#senfineCourseEnquiry" c-name={course.course} className="btn btn-theme btn-xsm scourse b2">Apply Now</Link>
                                <h4><Link to={`/courses/${course.slug}`}>{course.course}</Link></h4>
                                <p>
                                {course.institution}
                                </p>
                                <div className="author-info">
                                    <div className="others">
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                            <span> {course.rating} ({course.rating_count})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                    <div className="row flex tab-pane fade" id="catPopular">
                    {popularcourses.map((course, index) => (	
                        <div className="col-sm-12 col-md-3" key={index}>
                        <div className="item">
                            <div className="thumb">
                                <Link to={`/courses/${course.slug}`}>
                                    <img src={`/storage/courses/${course.image}`} loading="lazy" draggable="false" alt={course.course} />
                                </Link>
                            </div>
                            <div className="info">
                                <Link to="#senfineCourseEnquiry" data-toggle="modal" data-target="#senfineCourseEnquiry" c-name={course.course} className="btn btn-theme btn-xsm scourse b2">Apply Now</Link>
                                <h4><Link to={`/courses/${course.slug}`}>{course.course}</Link></h4>
                                <p>
                                {course.institution}
                                </p>
                                <div className="author-info">
                                    <div className="others">
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                            <span> {course.rating} ({course.rating_count})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                    <div className="row flex tab-pane fade" id="catFeatured">
                    {featuredcourses.map((course, index) => (
                        <div className="col-sm-12 col-md-3" key={index}>
                        <div className="item">
                            <div className="thumb">
                                <Link to={`/courses/${course.slug}`}>
                                    <img src={`/storage/courses/${course.image}`} loading="lazy" draggable="false" alt={course.course} />
                                </Link>
                            </div>
                            <div className="info">
                                <Link to="#senfineCourseEnquiry" data-toggle="modal" data-target="#senfineCourseEnquiry" c-name={course.course} className="btn btn-theme btn-xsm scourse b2">Apply Now</Link>
                                <h4><Link to={`/courses/${course.slug}`}>{course.course}</Link></h4>
                                <p>
                                {course.institution}
                                </p>
                                <div className="author-info">
                                    <div className="others">
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                            <span> {course.rating} ({course.rating_count})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                  </div>
                </div>
            </div>
            )}
        </div> 
    </div>
 </div>
</div>
    :
    <h1> Can't connect to server ! </h1>    
  );
}

export default Courses;