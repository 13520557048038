import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import Pagination from './Pagination';
import striptags from "striptags";
import { RotatingLines } from 'react-loader-spinner';
import axios from 'axios';
import "./Blogs.css";

function Blogs() {

    const [blogs, setBlogs] = useState([]);
    const [articles, setArticles] = useState([]);
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
useEffect(()=>{
    const fetchProduct = async () => {
        setIsLoading(true)
        await axios.get(`/all-blogs`).then(({data})=>{
            setBlogs(data.blogs)
            setArticles(data.articles)
            setCourses(data.trendcourses)
            setIsLoading(false)
          // console.log(data);
        }).catch(({response:{data}})=>{
            setIsLoading(false)
            Swal.fire({
                text:'Error!.',
                icon:"error"
            })
        })
      }
    fetchProduct();         
},[])    
        function truncate (str) {
            return str.length > 250 ? str.substring(0, 200) + "..." : str;
        } 
        function blogDate(par){
            const today = new Date(par);
            const date = today.getDate();
            return date;
          }
        function blogMonth(par){
            const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            const today = new Date(par);
            const month = months[today.getMonth()];
            return month;
          }
        function blogYear(par){
            const today = new Date(par);
            const year = today.getFullYear();
            return year;
          }
  return ( 
    blogs!==null?
    <div className="blogs">
      <div className="breadcrumb-area shadow dark text-left bg-cover text-light" style={{backgroundImage: 'url(/assets/img/inner-banner-bg.jpg'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Best Trending Blog Topics</h1>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Blogs / Articles</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="banner-top-img">
            <img className="img-responsive" src="/assets/img/courses-img.png" loading="lazy" draggable="false" />
        </div>
      </div>
      <div className="blog-area full-blog right-sidebar full-blog default-padding">
      {isLoading ? (
      <div className="row">
      <div className="col-md-12 text-center">
          <RotatingLines
             visible={true}
             height="40"
             width="40"
             strokeColor="#0476bc"
             strokeWidth="4"
             animationDuration="0.75"
             ariaLabel="Loading"
          />
          <p>Please wait...</p>
      </div>
      </div>
        ) : (
        <div className="container">
            <div className="row">
                <div className="blog-items">
                <div className="blog-content col-md-8">
                        {blogs && blogs.length > 0 && blogs.map((blog, index) => {
			        		return (
                            <div className="single-item" key={`b${blog.id}`}>
                                <div className="item horizontal row flex middle">
                                    <div className="col-md-5 col-xs-5">
                                    <div className="thumb">
                                        <Link to={`/blogs/${blog.slug}`}><img src={`/storage/blogs/${blog.image}`} loading="lazy" draggable="false" alt={blog.title} /></Link>
                                        <div className="date">
                                        <h4><span>{blogDate(new Date(blog.updated_at))}</span> {blogMonth(new Date(blog.updated_at))}, {blogYear(new Date(blog.updated_at))}</h4>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-7 col-xs-7">
                                    <div className="info">
                                        <h4>
                                            <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
                                        </h4>
                                        <p className="hidden-xs">
                                            {parse(truncate(striptags(blog.content)))}
                                        </p>
                                        <Link to={`/blogs/${blog.slug}`}>Read More <i className="fas fa-angle-double-right"></i></Link>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            );
                        })}
                    {/* <div className="row">
                        <div className="col-md-12 pagi-area">
                        <nav aria-label="navigation">
                        <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                        </nav>
                        </div>
                    </div> */}
                </div>
                <div className="col-md-4">
                    <div className="sidebar">
                        <aside>
                        <div className="sidebar-item recent-post side-articles">
                                <div className="title">
                                    <h4>Articles</h4>
                                </div>
                                {articles && articles.length > 0 && articles.map((article, index) => {
			        		return (
                                <div className="item"  key={`b${article.id}`}>
                                    <div className="content">
                                        <div className="thumb">
                                            <Link to={`/articles/${article.slug}`}>
                                                <img src={`/storage/articles/${article.image}`} loading="lazy" draggable="false" alt={article.title}/>
                                            </Link>
                                        </div>
                                        <div className="info">
                                            <h4>
                                                <Link to={`/articles/${article.slug}`}> {article.title}</Link>
                                            </h4>
                                            <div className="meta">
                                                <i className="fas fa-calendar"></i> {blogDate(new Date(article.updated_at))} {blogMonth(new Date(article.updated_at))} {blogYear(new Date(article.updated_at))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                );
                            })}
                                
                            </div>
                            <div className="sidebar-item category">
                                <div className="title">
                                    <h4>Trending Courses</h4>
                                </div>
                                <div className="sidebar-info">
                                    <ul>
                                        {courses.map((course) => {
                                            return(
                                        <li key={`c${course.id}`}><Link to={`/courses/${course.slug}`}>{course.course}</Link></li>
                                        );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
                </div>
            </div>
        </div>
        )}
    </div>   
 </div>
    :
    <h1> Can't connect to server ! </h1>
  );
}

export default Blogs;
